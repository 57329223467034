import { parseQueryIntoGodmotherRequest } from "helpers/cms-container-page-helpers";
import { Product } from "@rtr/godmother";
import ActionTypes from "actions/action-types";
import { createAction } from "redux-actions";

const actions = {
  fetchCarouselProductsSuccess: createAction(ActionTypes.LANDING_PAGE_CAROUSEL_PRODUCTS_SUCCESS),
  fetchCarouselProductsFailure: createAction(ActionTypes.LANDING_PAGE_CAROUSEL_PRODUCTS_FAILURE),

  /**
   * Iterate through content modules, and for any instances of grid query carousel, call for products.
   * If the initial products were provided from the serverside payload, append the remaining ones.
   * Otherwise, fetch all products for the carousel.
   */
  fetchCarouselProductsData: contentModules => {
    return dispatch => {
      contentModules
        .filter(module => {
          const hasQueryData = module.attributes?.queryData?.length;

          return module?.id && module?.component === "GridQueryCarousels" && hasQueryData;
        })
        .forEach(module => {
          module.attributes.queryData.forEach(async (qd, index) => {
            //Product carousels are initialized on the server by mapping queryData
            //We can assume they share the same index
            const associatedProducts = module.attributes.productCarousels[index];
            //If we attempted to fetch products on the server and there were none, don't try again.
            const noProductsInServersideFetch =
              module.attributes.serversideProductsFetched && !associatedProducts.products.length;

            if (noProductsInServersideFetch) {
              return;
            }

            //If initial products weren't requested serverside, request all of them now.
            const shouldRequestFullProductsList = !module.attributes.serversideProductsFetched;
            const parsedQuery = parseQueryIntoGodmotherRequest(qd, false, shouldRequestFullProductsList);

            return Product.list(parsedQuery)
              .then(products => {
                dispatch(actions.fetchCarouselProductsSuccess({ id: module.id, carouselIndex: index, products }));
              })
              .catch(e => {
                dispatch(actions.fetchCarouselProductsFailure(e));
              });
          });
        });
    };
  },
};

export const { fetchCarouselProductsData } = actions;
