import React from "react";
import PropTypes from "prop-types";
import ContentModulesContainer from "components/source/shared/content-modules-container";

export default class GenericHomepage extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    contentModules: PropTypes.array,
    imageLazyLoadIndex: PropTypes.number.isRequired,
  };

  render() {
    const websiteSchema = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.renttherunway.com/",
    };

    return (
      <div className="generic-homepage templatized-content" data-test-id="generic-homepage">
        {/* JSON-LD Schema for WebSite. Only needed on initial homepage vist by search engine */}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteSchema) }} />
        <ContentModulesContainer
          className={this.props.className}
          contentModules={this.props.contentModules}
          imageLazyLoadIndex={this.props.imageLazyLoadIndex}
        />
      </div>
    );
  }
}
