// by using universal-cookie, you can expect that this code will only work
// client-side. to make it work server-side, have your component pass the
// cookies object provided to it by the `withCookies` HOC.
import Cookies from "universal-cookie";
import ActionLogger from "action-logger";

const cookies = new Cookies();

const HOMEPAGE_VIEW_CACHE_KEY = "homepageViews";
const HOMEPAGE_TYPES = {
  GATED: "gated",
  GET_STARTED_NAV: "get_started_nav",
  GENERIC: "generic",
  PERSONALIZED: "onboarding_results",
  UNLIMITED: "unlimited",
  UPDATE: "rtr_update",
};

function getHomepageViewCookie() {
  return cookies.get(HOMEPAGE_VIEW_CACHE_KEY);
}

function getHomepageViewCount() {
  if (!getHomepageViewCookie()) {
    return 0;
  }
  return parseInt(getHomepageViewCookie(), 10);
}

//this should be called before updating the homepage view count
function logHomepageLoad(homepageType) {
  const homepageViews = getHomepageViewCount();

  const pixelData = {
    object_type: "homepage",
    action: "page_load",
    page_type: homepageType,
    nth_homepage_view: homepageViews + 1,
  };

  ActionLogger.logAction(pixelData);
}

function logHomepagePickState(objectType, state, membershipType, pro) {
  ActionLogger.logAction({
    object_type: objectType,
    action: "mode",
    state: state,
    membership: membershipType,
    pro: pro,
  });
}

function updateHomepageViewCount() {
  const homepageViews = getHomepageViewCount();
  cookies.set(HOMEPAGE_VIEW_CACHE_KEY, homepageViews + 1, { path: "/" });
}

export default {
  HOMEPAGE_TYPES,
  logHomepageLoad,
  logHomepagePickState,
  updateHomepageViewCount,
};
