import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ActionLogger from "action-logger";
import ContentModule from "./content-module.jsx";
import { membershipStatePropType, userDataPropType } from "components/propTypes";
import { LazyImageProvider } from "../../layout/LazyImageContext";

export class ContentModulesContainerComponent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    contentModules: PropTypes.array,
    imageLazyLoadIndex: PropTypes.number,
    membershipState: membershipStatePropType,
    pageName: PropTypes.string,
    runningExperiments: PropTypes.array,
    userData: userDataPropType,
  };

  componentDidMount() {
    this.logComponents();
  }

  logComponents = () => {
    ActionLogger.logAction({
      object_type: this.props.pageName,
      action: "page_content",
      content: this.props.contentModules?.map(m => m.template).join(", "),
    });
  };

  renderModule(imageLazyLoadIndex, module, index) {
    return (
      <ContentModule
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...module}
        globalProps={this.props}
        key={index}
        index={index}
      />
    );
  }

  render() {
    const className = ["templatized-content-container", this.props.className || ""].join(" ");

    // the index at which we can safely start lazy loading images

    let imageLazyLoadIndex = this.props.imageLazyLoadIndex;

    // default to the end of the list, so no images are lazy loaded
    if (!imageLazyLoadIndex) {
      imageLazyLoadIndex = this.props.contentModules?.length;
    }

    return (
      <div className={className} data-test-id="content-modules-container">
        <LazyImageProvider value={false}>
          {this.props.contentModules
            ?.slice(0, imageLazyLoadIndex)
            .map((module, index) => this.renderModule(imageLazyLoadIndex, module, index))}
        </LazyImageProvider>
        {/* Lazy load all images below the given index*/}
        <LazyImageProvider value={true}>
          {this.props.contentModules
            ?.slice(imageLazyLoadIndex)
            .map((module, index) => this.renderModule(imageLazyLoadIndex, module, index))}
        </LazyImageProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pageName } = state;

  return {
    browser: state.browser,
    pageName,
    membershipState: state.membershipState,
    userData: state.userData,
  };
}

export default connect(mapStateToProps)(ContentModulesContainerComponent);
