import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Instrumentation from "helpers/instrumentation";
import ActionUtils from "actions/action-utils";
import GenericHomepage from "components/source/homepage/generic-homepage";
import HomepageHelper from "helpers/homepage-helper";
import CmsServiceClient, { Locations } from "clients/CmsServiceClient";
import { isActiveMembership } from "helpers/membership-helpers";
import { membershipStatePropType } from "components/propTypes";
import { getRequestSessionUserId } from "../../../assets/javascripts/helpers/server-side-only/storefront-migration-helpers";
import { getEnvironmentName } from "helpers/environment-helpers";
import {
  getUserSegmentsFromGodmother,
  isUserInRtrUpdateSegment,
  setSegmentsCookiesForUser,
} from "helpers/server-side-only/segments-helpers";
import { CacheDurations, withCdnAndBrowserCaching } from "helpers/cache-control-helpers";
import { pageTypes } from "rtr-constants/pageMetaData";
import { getRequestDeviceType } from "helpers/server-side-only/request-helpers";
import TemplatizedContent from "helpers/TemplatizedContent";
import { fetchCarouselProductsData } from "actions/content-module-container-actions";
import { addSnackBar } from "actions/snack-bar-actions";
import { refreshSegments } from "helpers/segment-refresh-helper";
import { SFLogger } from "../../../assets/javascripts/logger/logger";
import { enforceAuthenticationForPageRoute } from "helpers/server-side-only/session-helpers";
import { AUTH_STATES } from "rtr-constants";

const PAGE_TYPE = pageTypes.HOME;

const propTypes = {
  contentModules: PropTypes.arrayOf(PropTypes.object),
  fromCache: PropTypes.bool,
  lazyLoadImageIndex: PropTypes.number.isRequired,
  membershipState: membershipStatePropType,
  rtrSession: PropTypes.shape({
    rtrId: PropTypes.string,
  }),
};

const returningHomeCmsPath = "/content/returning_home";

const logger = SFLogger("/pages/home/returning/index");

const withAuth = enforceAuthenticationForPageRoute({
  enforcedAuthState: AUTH_STATES.ANONYMOUS,
  logger,
});

export const getServerSideProps = withAuth(async context => {
  const { req, res } = context;

  Instrumentation.tagPage("homepage/returning");

  const userId = getRequestSessionUserId(req);

  // NW [EXPLANATION] 9/26/23: `seg` cookie must be set accurately before any potential redirect response, to ensure the user gets routed properly at the CDN
  const segments = await getUserSegmentsFromGodmother(userId);
  setSegmentsCookiesForUser(segments, res);

  // NW [EXPLANATION] 7/10/23: If user is in a subscriber segment, redirect to the CDN-served homepage for subscriber experience
  if (isUserInRtrUpdateSegment(segments)) {
    const environmentName = getEnvironmentName().toLowerCase();
    let destination = "https://www.renttherunway.com";
    if (environmentName === "development") {
      destination = "http://localhost:3000/home/member";
    } else if (environmentName === "stage") {
      destination = "https://stage.renttherunway.com";
    } else if (environmentName === "qa") {
      destination = "https://qa.renttherunway.com";
    }

    return {
      redirect: {
        destination,
        permanent: false,
      },
    };
  }
  withCdnAndBrowserCaching(context.res, context.req, { "s-maxage": CacheDurations.MODEST });

  const deviceType = getRequestDeviceType(context);
  const templatizedContent = await TemplatizedContent.buildTemplatizedContent(returningHomeCmsPath, deviceType);

  const pageMetadata = await CmsServiceClient.getInstance().getContent(encodeURIComponent("/"), Locations.pageMetadata);

  return {
    props: {
      fromCache: templatizedContent.contentModules.some(cm => cm.fromCache),
      initialReduxState: { contentModules: templatizedContent.contentModules },
      lazyLoadImageIndex: templatizedContent.lazyLoadIndex ?? 0,
      pageMetadata,
    },
  };
});

function ReturningHomepage(props) {
  const dispatch = useDispatch();
  const navigateSubscriberToSubscriberHomepage = membershipState => {
    if (window?.location && isActiveMembership(membershipState)) {
      const currentHost = window.location.host?.toLowerCase?.() || "";
      if (
        currentHost.includes("storefront-next.stage.gcp.rtr.cloud") ||
        currentHost.includes("storefront-next.prod.gcp.rtr.cloud") ||
        currentHost.includes("storefront-next.qa.gcp.rtr.cloud") ||
        currentHost.includes("localhost:3000")
      ) {
        //sbenedict [Explanation] 4/21/24 the CDN handles routing from / based on segment cookies, but non-CDN does not
        //In this case, explicitly redirect the user to the subscriber homepage
        ActionUtils.redirectToRoute("/home/member");
      } else {
        //In a CDN-served environment, we need to refresh the segment cookie in the client before redirecting
        //Otherwise, the CDN can continuously serve the RHP from the cache if the cookie is incorrect or missing
        refreshSegments(true).then(ActionUtils.refreshPage);
      }
    }
  };

  useEffect(() => {
    HomepageHelper.logHomepageLoad(HomepageHelper.HOMEPAGE_TYPES.GENERIC);
    HomepageHelper.updateHomepageViewCount();
    const fullUrl = new URL(window.location.href);
    const errorQueryParam = fullUrl.searchParams.get("error");
    if (errorQueryParam) {
      dispatch(addSnackBar({ copyPrimary: errorQueryParam, duration: 5000 }));
      // Remove the error param from URL so that subsequent login attempts don't preserve the error param in callback URL
      const params = new URLSearchParams(fullUrl.search);
      params.delete("error");
      const urlWithoutErrorParam = fullUrl.origin + fullUrl.pathname + params.toString();
      window.history.pushState(window.history.state, "", urlWithoutErrorParam);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCarouselProductsData(props.contentModules));
  }, []);

  useEffect(() => {
    navigateSubscriberToSubscriberHomepage(props.membershipState);
  }, [props.membershipState]);

  return (
    <GenericHomepage
      contentModules={props.contentModules}
      fromCache={props.fromCache}
      imageLazyLoadIndex={props.lazyLoadImageIndex}
    />
  );
}

ReturningHomepage.pageName = PAGE_TYPE;
ReturningHomepage.pageType = PAGE_TYPE;
ReturningHomepage.propTypes = propTypes;
ReturningHomepage.getCssChunkNames = () => ["application-home"];
ReturningHomepage.getHeadData = pageProps => {
  const { pageMetadata } = pageProps;

  return {
    metadata: {
      ...pageMetadata,
      pageName: PAGE_TYPE,
      pageType: PAGE_TYPE,
    },
  };
};

const mapStateToProps = state => {
  return {
    contentModules: state.contentModules,
    membershipState: state.membershipState,
    rtrSession: state.rtrSession,
  };
};

export default connect(mapStateToProps)(ReturningHomepage);
